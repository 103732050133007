import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import HeroHeader from '../components/heroHeader'
// import WaveHeader from "../components/waveHeader"
import PostsGridEachCateg from "../components/postsGridEachCateg"

const NotFoundPage = () => {

  const data = useStaticQuery(graphql`
  {
      image404: file(name: {eq: "404-Pic"}) {
      childImageSharp {
          fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          }
      }
    }
  }
  `
  )

  return (
    <Layout>
      <Seo title="404: Not found" />

      <HeroHeader
        heroImage={{ localFile: data.image404 }}  // same format as for posts (bit hacky..)
        title={`Page not found`}
        pageOrPost='PAGE'
      />

      {/* <WaveHeader
        title={`Page not found`}
        pageOrPost='PAGE'
      /> */}

      <div className={`content-container`}
        style={{
          marginTop: '3rem',
          marginBottom: '5rem',
        }}
      >

        <h3>Sorry.. that page doesn't exist (or maybe it's hiding).</h3>
        <h3>Try one of these instead? <span role="img" aria-label="index finger pointing down">👇</span></h3>

      </div>
      <PostsGridEachCateg />

    </Layout>
  )
}
export default NotFoundPage
